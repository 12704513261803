@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
}

html,
body {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.app-container {
  display: flex;
  gap: 100px;
}

h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.image-container {
  background-size: cover;
  background-image: url('./assets/landing.jpg');
  height: 100vh;
  width: 50vw;
}

.firm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.services {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-top: 30px;
  text-align: left;
  line-height: 1.3;
  align-items: center;
}
.service-list {
  list-style-type: none;
  padding: 0;
}
.service-list li::before {
  content: '\2022';
  color: #000;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.service-list-container {
  display: flex;
  gap: 48px;
}

.message {
  padding: 0 52px 0 20px;
  line-height: 1.3;
  text-align: justify;
  max-width: 50vw;
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-buttons {
  display: flex;
  margin-top: 20px;
}
.button {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border: 2px solid #000;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.button:hover {
  background-color: #fff;
  color: #000;
}

.footer {
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c; /* Darker black */
  color: #fff; /* White text */
  min-height: 100vh;
  min-width: 100vw;
  justify-content: space-between;
  background-image: url('./assets/footer-back.jpg');
  background-size: cover;
  position: relative; /* Add this */
}

.footer-content {
  position: relative;
  z-index: 1;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 100px;
}

.footer-section {
  flex: 1;
  display: flex;
  padding: 0 20px;
}

#legal-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.legal-info__parts {
  display: flex;
  flex-direction: row;
  gap: 100px;
  line-height: 1.3;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.footer-bottom p {
  opacity: 0.7;
}

.footer a {
  color: #fff; /* White link color */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  position: relative;
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
  z-index: -1; /* Place the overlay below the content */
}

.footer a:hover {
  color: #ffcc00; /* Hover color (yellow) */
  text-decoration: underline; /* Underline on hover */
}

/* Three-column layout for the legal information sections */
#privacy-policy,
#terms-of-service,
#disclaimer {
  margin-bottom: 20px;
  flex: 1;
}

#privacy-policy h5,
#terms-of-service h5,
#disclaimer h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Style for contact information */
.footer-section p {
  font-size: 16px;
}

.footer-address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  line-height: 1.3;
}

.address {
  max-width: 500px;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
    gap: 0;
  }

  .image-container {
    width: 100vw;
    height: 50vh;
  }

  .firm-container {
    padding: 20px;
    text-align: center;
  }

  .message {
    padding: 0;
  }

  .service-list-container {
    gap: 0px;
  }

  .message,
  .service-list-container {
    max-width: 100%;
    font-size: 18px;
  }

  .service-list-container {
    flex-direction: column;
  }

  h2 {
    font-size: 30px; /* Reduce font-size for h2 heading */
    margin-top: 10px; /* Adjust top margin for better spacing */
  }

  .legal-info__parts {
    display: flex;
    flex-direction: column;
    gap: 0px;
    line-height: 1.3;
    font-size: 16px;
  }
}
